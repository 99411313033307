import React from 'react';

class Contact extends React.Component {

    render()
    {
        return(
            <div className="contact-area section-bg" data-spy="scroll" data-offset="0">
                <div id="contact" className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-sm-12" style={{textAlign: "left"}}>
                            <div className="section-title relative">
                                <div className="mt-15">
                                    <h6>
                                        Trattoria Dell' Oratoriënhof
                                    </h6>
                                    <p>Mechelsestraat 111<br />
                                    3000 Leuven
                                    </p>
                                </div>
                                <div className="mb-20">
                                    <h6><span>Reservaties via telefoon</span></h6>
                                        +32 16 22 81 05
                                </div>
                                <div className="mb-20">
                                    <h6><span>Openingsdagen</span></h6>
                                    <p>
                                        Alle dagen, behalve maandag, zon- en feestdagen en zaterdagmiddag.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12" style={{textAlign: "right"}}>
                            <div className="section-title relative">
                                <div className="mt-15">
                                    <h6><span>Openingsuren Keuken</span></h6>
                                    <ul>
                                        <li>    van 12.00u tot 14.00u</li>
                                        <li>    van 18.00u tot 22.00u</li>
                                    </ul>
                                </div>
                                <div className="mb-20">
                                    <h6><span>Openingsuren Trattoria</span></h6>
                                    <ul>
                                        <li>    van 12.00u tot 15.00u</li>
                                        <li>    van 18.00u tot 22.30u</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact
