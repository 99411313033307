import React from 'react';

import { FiFacebook, FiHeart } from "react-icons/fi";

class Footer extends React.Component {

    render()
    {
        return (
            <div className="footer-area section-gap">
                <div className="container">
                    <div className="footer-bottom row align-items-center justify-content-between">
                        <p className="footer-text m-0 col-lg-6 col-md-12" style={{color: "white"}}>Copyright © 2025 All rights reserved. Made with <FiHeart /> in Leuven.</p>
                        <div className="col-lg-6 col-sm-12 footer-social">
                            <a href="https://www.facebook.com/Trattoria-dell-Oratori%C3%ABnhof-147841655280977/" target="_blank" rel="noopener noreferrer"><FiFacebook /></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;
